<template>
  <div class="row">
    <div class="card form-body">
      <div class="card-body">
        <nav
          class="nav nav-pills nav-justified"
          v-if="currentUser.u.is_admin == 0"
        >
          <a
            class="nav-link"
            @click="active_tab = 'rate'"
            :class="active_tab == 'rate' ? 'active' : ''"
            aria-current="page"
            href="#"
          >
            Services</a
          >
          <a
            class="nav-link"
            @click="active_tab = 'archival'"
            :class="active_tab == 'archival' ? 'active' : ''"
            href="#"
          >
            Archivals</a
          >
          <a
            class="nav-link"
            @click="active_tab = 'fdleorirate'"
            :class="active_tab == 'fdleorirate' ? 'active' : ''"
            href="#"
          >
            Florida</a
          >
        </nav>
        <hr v-if="currentUser.u.is_admin == 0" />

        <!-- <v-map v-if="active_tab == 'rate'"></v-map>
          <v-sms v-if="active_tab == 'archival'"></v-sms> -->
        <v-archival v-if="active_tab == 'archival'"></v-archival>
        <v-rate v-else-if="active_tab == 'rate'"></v-rate>
        <v-fdle-ori-rates v-else></v-fdle-ori-rates>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/mutations.type";
import { mapGetters } from "vuex";
import vRate from "@/pages/backend/master/location/rate";
import vArchival from "@/pages/backend/master/location/archivalrate";
import vFdleOriRates from "@/pages/backend/master/location/fdleorirates";
export default {
  components: {
    vRate,
    vArchival,
    vFdleOriRates
  },
  data() {
    return {
      active_tab: "rate",
      breadCrumbs: [
        {
          id: 1,
          title: "Dashboard",
          route: ""
        },
        {
          id: 2,
          title: "Locations",
          route: ""
        },
        {
          id: 3,
          title: "Rates",
          route: ""
        }
      ]
    };
  },
  computed: {
    ...mapGetters(["currentUser"])
  },
  created() {
    if (this.$route.name == "client.map_config") {
      this.breadCrumbs[0].route = "admin.location";
      this.breadCrumbs[2].title = "Location";
      this.active_tab = "rate";
    } else {
      this.breadCrumbs[0].route = "client.dashboard";
      this.breadCrumbs[2].title = "Rates";
    }

    this.$store.commit(SET_BREADCRUMB, this.breadCrumbs);
  }
};
</script>
