<template>
  <div class="row">
    <div class="col-md-12" style="background: white">
      <div class="card form-body" style="border:none">
        <div class="card-body">
          <legend>
            <h6>Florida Rates | {{ client.name }}</h6>
          </legend>

          <!-- <span v-if="serviceError" class="text-danger">{{
              serviceError
            }}</span> -->
          <hr />
          <div
            style="display: flex; justify-content: flex-end; align-items: center;"
          >
            <!-- <div class="form-group form-inline-flex">
              <input
                type="checkbox"
                v-model="checkallmarkup"
                @change="checkMarkupAllResult_()"
              /><span class="ml-2 mr-5" style="font-weight: 600;"
                >Default Markup :
              </span>
              <input
                v-on:keyup="changetaxpercentage(index)"
                type="number"
                min="0"
                name=""
                class="form-control schedule_input"
                id=""
                v-model="default_markup"
              />

              <input
                type="checkbox"
                v-model="checkalltax"
                @change="checkTaxAllResult_()"
              />

              <span class="ml-2" style="font-weight: 600;">Default Tax: </span>
              <input
                v-on:keyup="changetaxpercentage(index)"
                type="number"
                min="0"
                name=""
                class="form-control schedule_input"
                id=""
                v-model="default_tax"
              />
            </div> -->

            <div>
              <span>
                <button
                  class="btn btn-sm btn-secondary font-weight-bolder mb-2"
                  style="float: right"
                  @click="Refresh()"
                >
                  Refresh
                </button>
              </span>
            </div>
          </div>
          <table class="table table-bordered">
            <thead style="position: sticky; top: -1rem; background: white;">
              <tr>
                <th></th>
                <th class="text-center">Default</th>
                <th colspan="8" class="text-center">Custom</th>
              </tr>

              <tr>
                <th class="text-left">ORI</th>
                <th class="text-right">Cost($)</th>
                <th class="text-right">Default Markup($)</th>
                <th class="text-right">Markup($)</th>
                <th class="text-right">Default Commission($)</th>
                <th class="text-right">Commission($)</th>

                <th class="text-right">Sub Total($)</th>
                <th class="text-right">Default Tax(%)</th>
                <th class="text-right">Tax(%)</th>
                <th class="text-right">Selling Price($)</th>
              </tr>
            </thead>
            <tbody v-if="!loadingData && table_datas.length > 0">
              <tr v-for="(data, index) in table_datas" :key="index">
                <td class="text-right">{{ data.service_ori }}</td>
                <td class="text-right">{{ data.cost }}</td>
                <td class="text-right">
                  {{ data.default_markup }}
                </td>
                <td>
                  <input
                    v-on:keyup="changemarkup(index)"
                    type="number"
                    min="0"
                    name=""
                    class="form-control table-form-control"
                    id=""
                    v-model="data.markup"
                  />
                </td>
                <td class="text-right">{{ data.default_commission }}</td>
                <td>
                  <input
                    v-on:keyup="changecommission(index)"
                    type="number"
                    min="0"
                    name=""
                    class="form-control table-form-control"
                    id=""
                    v-model="data.commission_percentage"
                  />
                </td>
                <td class="text-right">{{ data.sub_total }}</td>
                <td class="text-right">{{ data.default_tax }}</td>

                <td>
                  <input
                    v-on:keyup="changetaxpercentage(index)"
                    type="number"
                    min="0"
                    name=""
                    class="form-control table-form-control"
                    id=""
                    v-model="data.tax_percentage"
                  />
                </td>
                <td class="text-right">{{ data.selling_price }}</td>
              </tr>
            </tbody>
            <tbody v-if="loadingData">
              <tr>
                <th class="text-center" colspan="10">
                  <vDataLoader></vDataLoader>
                </th>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="card-footer">
          <button
            class="btn btn-success ml-3"
            style="float: right"
            @click="update('S', 'kt_save_service')"
            ref="kt_save_service"
          >
            Update
          </button>

          <button
            ref="kt_save_add_another_company"
            class="btn btn-danger mr-3"
            @click="goBack()"
            style="float: right"
          >
            <i class="fa fa-arrow-left"></i> Back
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import vDataLoader from "@/components/frontend/spinner.vue";
import { SET_BREADCRUMB } from "@/core/services/store/mutations.type";
import {
  FETCH_LOCATION_ORI_RATE_LISTS,
  UPDATE_LOCATION_ORI_RATE_LISTS
} from "@/core/services/store/actions.type";
import { mapGetters } from "vuex";
export default {
  components: {
    vDataLoader
  },
  data() {
    return {
      breadCrumbs: [
        {
          id: 1,
          title: "Dashboard",
          route: "client.dashboard"
        },
        {
          id: 2,
          title: "Locations",
          route: "client.location"
        },
        {
          id: 3,
          title: "Update Rate ",
          route: ""
        }
      ],
      table_datas: [],
      errors: [],
      loadingData: false,
      serviceError: "",
      selectAll: false,
      service_ori: [],
      rates: [],
      client: [],
      checkallmarkup: false,
      checkalltax: false,
      checkallcommission: false,
      set_default_markup: "",
      set_default_commission: "",
      set_default_tax: ""
    };
  },
  computed: {
    ...mapGetters(["getServiceOriData"])
  },
  created() {
    this.breadCrumbs[2].title = "Edit Rate";
    this.$store.commit(SET_BREADCRUMB, this.breadCrumbs);
    // this.getLocationRateLists();
    this.getServiceData();
  },
  methods: {
    goBack() {
      var client_slug = this.$route.params.client_slug;
      this.$router.push({
        name: "client.location",
        params: { client_slug: client_slug }
      });
    },
    selectAllServices() {
      if (this.selectAll) {
        this.table_datas.filter(service_ori => {
          return (service_ori.checked = true);
        });
      } else {
        this.table_datas.filter(service_ori => {
          return (service_ori.checked = false);
        });
      }
    },
    closeBtnLoad(ref) {
      const submitButton = this.$refs[ref];
      submitButton.classList.remove(
        "spinner",
        "spinner-light",
        "spinner-right"
      );
      submitButton.disabled = false;
    },
    loadingButton(ref) {
      const submitButton = this.$refs[ref];
      submitButton.disabled = true;
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");
    },
    getLocationRateLists() {
      this.loadingData = true;
    },
    getServiceData() {
      this.loadingData = true;
      this.table_datas = [];
      var data_ = { id: this.$route.params.id, code: "fdle" };
      this.$store
        .dispatch(FETCH_LOCATION_ORI_RATE_LISTS, data_)
        .then(data => {
          this.rates = data.rates;
          this.client = data.client;
          this.set_default_markup = data.client.default_markup;
          this.set_default_tax = data.client.default_tax;
          this.set_default_commission = data.client.default_commission;
          // if (this.client.default_markup == "") {
          //   this.client.default_markup = this.client.location_default_markup;
          // }
          // if (this.client.default_tax == "") {
          //   this.client.default_tax = this.client.location_default_tax;
          // }
          // if (this.client.default_commission == "") {
          //   this.client.default_commission = this.client.location_default_commission;
          // }
          // console.log(this.rates);
          this.rates.map(service_ori => {
            if (service_ori.location_service_ori_id) {
              this.table_datas.push({
                id: service_ori.id,
                service_ori: service_ori.ori_number,
                checked: true,
                cost: service_ori.cost,
                default_markup:
                  service_ori.default_ori_markup != ""
                    ? service_ori.default_ori_markup
                    : "",
                default_commission:
                  service_ori.default_ori_commission_percentage != ""
                    ? service_ori.default_ori_commission_percentage
                    : "",
                default_tax:
                  service_ori.default_ori_tax_percentage != ""
                    ? service_ori.default_ori_tax_percentage
                    : "",
                markup: service_ori.markup ?? "",
                sub_total: service_ori.sub_total,
                tax_percentage: service_ori.location_tax_percentage ?? "",
                commission_percentage: service_ori.commission_percentage ?? "",
                selling_price: service_ori.selling_price
              });
            } else {
              this.table_datas.push({
                id: service_ori.id,
                service_ori: service_ori.ori_number,
                checked: false,
                cost: service_ori.cost,
                markup: service_ori.markup ?? "",
                default_markup:
                  service_ori.default_ori_markup != ""
                    ? service_ori.default_ori_markup
                    : "",
                default_commission:
                  service_ori.default_ori_commission_percentage != ""
                    ? service_ori.default_ori_commission_percentage
                    : "",
                default_tax:
                  service_ori.default_ori_tax_percentage != ""
                    ? service_ori.default_ori_tax_percentage
                    : "",
                sub_total: service_ori.sub_total,
                tax_percentage: service_ori.location_tax_percentage ?? "",
                commission_percentage: service_ori.commission_percentage ?? "",
                selling_price: service_ori.selling_price
              });
            }
          });
          this.loadingData = false;
        })
        .catch(() => {
          this.loadingData = false;
        });
    },

    changecommission(index) {
      var cost =
        this.table_datas[index].cost != ""
          ? parseFloat(this.table_datas[index].cost)
          : 0;
      var commission =
        event.target.value == ""
          ? this.table_datas[index].default_commission == ""
            ? 0
            : parseFloat(this.table_datas[index].default_commission)
          : parseFloat(event.target.value);
      var markup_rate =
        this.table_datas[index].markup == ""
          ? this.table_datas[index].default_markup == ""
            ? 0
            : parseFloat(this.table_datas[index].default_markup)
          : parseFloat(this.table_datas[index].markup);
      var tax =
        this.table_datas[index].tax_percentage == ""
          ? this.table_datas[index].default_tax == ""
            ? 0
            : parseFloat(this.table_datas[index].default_tax)
          : parseFloat(this.table_datas[index].tax_percentage);
      this.table_datas[index].sub_total = cost + markup_rate + commission;
      var selling_price = parseFloat(
        cost +
          markup_rate +
          commission +
          ((cost + markup_rate + commission) * tax) / 100
      );
      this.table_datas[index].selling_price = (
        Math.round(selling_price * 100) / 100
      ).toFixed(2);
    },
    changemarkup(index) {
      var cost =
        this.table_datas[index].cost != ""
          ? parseFloat(this.table_datas[index].cost)
          : 0;
      var commission =
        this.table_datas[index].commission_percentage == ""
          ? this.table_datas[index].default_commission == ""
            ? 0
            : parseFloat(this.table_datas[index].default_commission)
          : parseFloat(this.table_datas[index].commission_percentage);
      var markup_rate =
        event.target.value == ""
          ? this.table_datas[index].default_markup == ""
            ? 0
            : parseFloat(this.table_datas[index].default_markup)
          : parseFloat(event.target.value);
      var tax =
        this.table_datas[index].tax_percentage == ""
          ? this.table_datas[index].default_tax == ""
            ? 0
            : parseFloat(this.table_datas[index].default_tax)
          : parseFloat(this.table_datas[index].tax_percentage);
      this.table_datas[index].sub_total = cost + markup_rate + commission;
      var selling_price = parseFloat(
        cost +
          markup_rate +
          commission +
          ((cost + markup_rate + commission) * tax) / 100
      );
      this.table_datas[index].selling_price = (
        Math.round(selling_price * 100) / 100
      ).toFixed(2);
    },
    changetaxpercentage(index) {
      var cost =
        this.table_datas[index].cost != ""
          ? parseFloat(this.table_datas[index].cost)
          : 0;
      var commission =
        this.table_datas[index].commission_percentage == ""
          ? this.table_datas[index].default_commission == ""
            ? 0
            : parseFloat(this.table_datas[index].default_commission)
          : parseFloat(this.table_datas[index].commission_percentage);
      var markup_rate =
        this.table_datas[index].markup == ""
          ? this.table_datas[index].default_markup == ""
            ? 0
            : parseFloat(this.table_datas[index].default_markup)
          : parseFloat(this.table_datas[index].markup);
      var tax =
        event.target.value == ""
          ? this.table_datas[index].default_tax == ""
            ? 0
            : parseFloat(this.table_datas[index].default_tax)
          : parseFloat(event.target.value);
      this.table_datas[index].sub_total = cost + markup_rate + commission;
      var selling_price = parseFloat(
        cost +
          markup_rate +
          commission +
          ((cost + markup_rate + commission) * tax) / 100
      );
      this.table_datas[index].selling_price = (
        Math.round(selling_price * 100) / 100
      ).toFixed(2);
    },
    update(saveType, ref) {
      this.errors = [];
      this.loadingButton(ref);
      let _data = {};
      _data.location_id = this.$route.params.id;
      _data.code = this.client.service_type;
      _data.default_rate = "";
      _data.default_tax = "";
      _data.default_commission = "";
      _data.rate_list = this.table_datas;

      this.$store
        .dispatch(UPDATE_LOCATION_ORI_RATE_LISTS, _data)
        .then(data => {
          this.$toastr.s(data.msg);
          this.closeBtnLoad(ref);
          this.getServiceData();
        })
        .catch(err => {
          this.errors = err;
          this.selectAll = false;
          this.serviceError = "";

          this.getLocationRateLists();
          this.closeBtnLoad(ref);
        });
    },
    Refresh() {
      this.checkallmarkup = false;
      this.checkalltax = false;
      this.checkallcommission = false;
      this.set_default_markup = "";
      this.set_default_tax = "";
      this.set_default_commission = "";
      this.table_datas = [];
      this.getServiceData();
    }
  }
};
</script>

<style scoped>
input[type="number"] {
  -moz-appearance: textfield !important;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
